<template>
  <div :class="{ 'isRendered': isRendered }">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="estimatedCapacityChartSeries"
      @updated="onChartUpdated"
    />
  </div>
</template>

<script>
import moment from '@/libs/moment'
import NetWorkerChartMixin from './NetWorkerChartMixin'

export default {
  mixins: [NetWorkerChartMixin],
  data() {
    return {
      isRendered: false,
      chartOptions: {
        chart: {
          id: 'estimatedCapacityChart',
          type: 'line',
          group: this.$props.chartGroup,
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          width: [5, 0, 0],
          curve: 'smooth',
        },
        title: {
          text: 'Estimated Capacity',
          align: 'left',
        },
        yaxis: {
          title: {
            text: 'Capacity',
          },
          labels: {
            formatter(val) {
              return `${(val).toFixed(0)} TB`
            },
          },
          minWidth: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val) {
                return `${(val).toFixed(0)} TB`
              }
              return null
            },
          },
          x: {
            formatter(val) {
              return moment.utc(val).format('L')
            },
          },
        },
      },
    }
  },
  computed: {
    estimatedCapacityChartSeries() {
      return [
        {
          name: 'Estimated Capacity',
          type: 'line',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.capacityEstimate / 1024 / 1024 / 1024 / 1024]),
        },
        {
          name: 'Applications',
          type: 'area',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.peakDailyApplications / 1024 / 1024 / 1024 / 1024]),
        },
        {
          name: 'Filesystems',
          type: 'area',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.totalLargestFilesystemFulls / 1024 / 1024 / 1024 / 1024]),
        },
      ]
    },
  },
}

</script>
