/* eslint-disable indent */
import VueApexCharts from 'vue-apexcharts'
import moment from '@/libs/moment'
import NetWorkerService from '@/service/networker.service'

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        chartGroup: {
            type: String,
            default: () => null,
        },
        statistic: {
            type: Array,
            default: () => [],
        },
        dataLoaded: {
            type: Boolean,
            default: false,
        },
        chartHeightNormalMode: {
            type: Number,
            default: 350,
        },
        chartHeightCompactMode: {
            type: Number,
            default: 150,
        },
        compactMode: {
            type: Boolean,
            default: false,
        },
        timeRange: {
            type: Object,
            default: () => ({
                startDate: moment().subtract(6, 'months').toDate(),
                endDate: new Date(),
            }),
        },
        standalone: {
            type: Boolean,
            default: true,
        },
        asup: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            chartDataLoaded: false,
            isRendered: false,
            chartOptions: {
              chart: {
                id: '',
              },
            },
            requestIncludedFields: [],
        }
    },
    computed: {
        asupId() {
            return this.$route.params.id
        },
        startDate() {
            if (this.$route.query.start) {
                return this.$route.query.start
            }

            return this.timeRange.startDate
        },
        endDate() {
            if (this.$route.query.end) {
                return this.$route.query.end
            }

            return this.timeRange.endDate
        },
        oldestStatistic() {
            return this.statistic
                .concat()
                .sort((a, b) => ((Date.parse(a.timestamp) > Date.parse((b.timestamp)) ? -1 : 1)))[0]
        },
        token() {
            return this.$route.query.token
        },
        isServerSideRendered() {
            return !!this.token
        },
        internalDataLoaded() {
            // state if data has been loaded. if server side rendering is activated, we check chartDataLoaded (set in this Vue instance). If it´s rendered normally, it will check dataLoaded, set by parent component
            if (this.isServerSideRendered === true) {
                return this.chartDataLoaded
            }

            return this.dataLoaded
        },
        chartHeight() {
            if (this.compactMode === true) {
                return this.chartHeightCompactMode
            }

            return this.chartHeightNormalMode
        },
    },
    watch: {
        compactMode() {
            this.updateChart()
        },
        timeRange() {
            this.updateChart()
        },
        statistic() {
            this.updateChart()
        },
    },
    mounted() {
        if (this.standalone === true) {
            this.loadStatistic()
        }
        this.updateChart()
    },
    methods: {
        adjustByTimezone(dateTime) {
            const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
            return dateTime.clone().subtract(offset, 'minutes')
        },
        onChartUpdated() {
            if (this.internalDataLoaded === true) {
                this.isRendered = true
            }
        },
        updateChart() {
            let opts = this.chartOptions

            // Update chart
            opts.chart = this.$apexcharts.merge(opts.chart, {
                height: this.chartHeight,
                animations: {
                    enabled: !this.isServerSideRendered,
                },
                toolbar: {
                    show: !this.isServerSideRendered,
                },
            })

            for (let i = 0; i < opts.yaxis.length; i += 1) {
                // Update all Y axis tick amount to prevent overlapping labels
                opts.yaxis[i] = this.$apexcharts.merge(opts.yaxis[i], {
                    tickAmount: this.compactMode === true ? 2 : 10,
                    title: {
                        style: {
                            fontSize: this.compactMode === true ? '8px' : '12px',
                        },
                    },
                })
            }

            const oldestStatistic = this.oldestStatistic?.timestamp
            const xAxisMax = moment(oldestStatistic) ?? moment(this.endDate).endOf('day')

            // Update X axis with new time range
            opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
                min: moment(this.startDate).startOf('day').valueOf(),
                max: xAxisMax.valueOf(),
            })

            opts = this.onBeforeChartUpdate(opts)

            this.$apexcharts.exec(this.chartOptions.chart.id, 'updateOptions', opts)
        },
        onBeforeChartUpdate(opts) {
            return opts
        },
        loadStatistic() {
            NetWorkerService.getStatisticListAsync(this.asupId, {
                from: moment(this.startDate).subtract(1, 'days').startOf('day').toISOString(),
                to: moment(this.endDate).add(1, 'days').endOf('day').toISOString(),
            }, {
                disableTenantFilter: true,
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            })
                .then(result => {
                    this.statistic = []
                    const processedDays = {}
                    for (let i = 0; i < result.items.length; i += 1) {
                        const day = moment(result.items[i].timestamp).startOf('day').toISOString()
                        if (!processedDays[day]) {
                            this.statistic.push(result.items[i])
                            processedDays[day] = true
                        }
                    }

                    this.chartDataLoaded = true
                    this.updateChart()
                })
        },
    },
}
