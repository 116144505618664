<template>
  <div :class="{ 'isRendered': isRendered }">
    <apexchart
      type="line"
      :options="chartOptions"
      :series="socketChartSeries"
      @updated="onChartUpdated"
    />
  </div>
</template>

<script>
import moment from '@/libs/moment'
import NetWorkerChartMixin from './NetWorkerChartMixin'

export default {
  mixins: [NetWorkerChartMixin],
  data() {
    return {
      isRendered: false,
      chartOptions: {
        chart: {
          id: 'socketChart',
          type: 'line',
          group: this.$props.chartGroup,
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        title: {
          text: 'Physical Sockets',
          align: 'left',
        },
        yaxis: {
          title: {
            text: 'Sockets',
          },
          minWidth: 0,
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          x: {
            // eslint-disable-next-line no-unused-vars
            formatter(val) {
              return moment.utc(val).format('L')
            },
          },
        },
      },
    }
  },
  computed: {
    socketChartSeries() {
      return [
        {
          name: 'Physical Sockets',
          type: 'line',
          data: this.statistic.map(x => [this.adjustByTimezone(this.$moment.utc(x.timestamp)).toISOString(), x.physicalSockets]),
        },
      ]
    },
  },
}

</script>
