<template>
  <div :class="{ 'isRendered': isRendered }">
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="capacityPerAppOverTimeChartSeries"
      @updated="onChartUpdated"
    />
  </div>
</template>

<script>
import NetWorkerChartMixin from './NetWorkerChartMixin'

export default {
  mixins: [NetWorkerChartMixin],
  props: {
    seriesPerApp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isRendered: false,
      chartOptions: {
        chart: {
          id: 'appCapacityOverTimeChart',
          type: 'bar',
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        stroke: {
          width: 0,
          curve: 'smooth',
        },
        title: {
          text: 'Max. Estimated Capacity per Application from X to Y',
          align: 'left',
        },
        yaxis: {
          title: {
            text: 'Capacity',
          },
          minWidth: 0,
        },
        xaxis: {
          categories: ['a'],
          labels: {
            formatter(val) {
              return `${(val).toFixed(0)} TB`
            },
          },
        },
        tooltip: {
          y: {
            // eslint-disable-next-line no-unused-vars
            formatter(val, config) {
              if (val) {
                return `${(val).toFixed(0)} TB`
              }
              return null
            },
          },
        },
      },
    }
  },
  computed: {
    capacityPerAppOverTimeChartSeries() {
      const values = this.seriesPerApp.map(x => x.data.reduce((m, a) => (a[1] > m ? a[1] : m), 0)) // get max estimated capacity

      return [{ name: 'Capacity', data: values }]
    },
  },
  methods: {
    onBeforeChartUpdate(opts) {
      // Update chart

      const appNames = this.seriesPerApp.map(x => x.name)

      // eslint-disable-next-line no-param-reassign
      return this.$apexcharts.merge(opts, {
        xaxis: {
          categories: appNames,
          min: undefined,
          max: undefined,
        },
        title: {
          text: `Max. Estimated Capacity per Application from ${this.$options.filters.formatDate(this.timeRange.startDate)} to ${this.$options.filters.formatDate(this.timeRange.endDate)}`,
        },
      })
    },
  },
}

</script>
