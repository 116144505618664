<template>
  <div>
    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last7Days', 'last30Days', 'last3Months', 'last6Months', 'thisYear', 'last2Years', 'last3Years', 'last5Years']"
            :timezone="asup.timezone"
          />
        </b-form-group>
      </b-form>
    </b-card>

    <b-overlay :show="isLoading">
      <b-row>
        <b-col cols="4">
          <b-card>
            <EstimatedCapacityChart
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :asup="asup"
            />
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <SocketChart
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :asup="asup"
            />
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <ClientsChart
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :asup="asup"
            />
          </b-card>
        </b-col>
        <b-col
          cols="6"
        >
          <b-card v-if="seriesPerApplication.length > 0">
            <EstimatedCapacityPerApp
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :series-per-app="seriesPerApplication"
              :asup="asup"
            />
          </b-card>
        </b-col>
        <b-col
          cols="6"
        >
          <b-card v-if="seriesPerApplication.length > 0">
            <EstimatedCapacityPerAppOverTimeChart
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :series-per-app="seriesPerApplication"
              :asup="asup"
            />
          </b-card>
        </b-col>
        <b-col
          cols="6"
        >
          <b-card v-if="seriesPerFilesystem.length > 0">
            <EstimatedCapacityPerFileSystem
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :series-per-filesystem="seriesPerFilesystem"
              :asup="asup"
            />
          </b-card>

        </b-col>
        <b-col
          cols="6"
        >
          <b-card v-if="seriesPerFilesystem.length > 0">
            <EstimatedCapacityPerFileSystemOverTimeChart
              chart-group="capacity"
              :statistic="stats"
              :data-loaded="statisticDataLoaded"
              :time-range="timeRange"
              :standalone="false"
              :series-per-filesystem="seriesPerFilesystem"
              :asup="asup"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, VBPopover, BForm, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import NetWorkerService from '@/service/networker.service'
import moment from '@/libs/moment'

import EstimatedCapacityChart from './charts/EstimatedCapacityChartView.vue'
import SocketChart from './charts/SocketChartView.vue'
import ClientsChart from './charts/ClientsChartView.vue'
import EstimatedCapacityPerApp from './charts/EstimatedCapacityPerAppView.vue'
import EstimatedCapacityPerAppOverTimeChart from './charts/EstimatedCapacityPerAppOverTimeChartView.vue'
import EstimatedCapacityPerFileSystem from './charts/EstimatedCapacityPerFileSystemView.vue'
import EstimatedCapacityPerFileSystemOverTimeChart from './charts/EstimatedCapacityPerFileSystemOverTimeChartView.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BOverlay,
    DateRangePicker,
    EstimatedCapacityChart,
    SocketChart,
    ClientsChart,
    EstimatedCapacityPerApp,
    EstimatedCapacityPerAppOverTimeChart,
    EstimatedCapacityPerFileSystem,
    EstimatedCapacityPerFileSystemOverTimeChart,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        startDate: moment().subtract(6, 'months').toDate(),
        endDate: new Date(),
      },
      isLoading: false,
      stats: [],
      statisticDataLoaded: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    seriesPerApplication() {
      const series = []

      if (this.stats.some(x => x.sharePoint > 0)) {
        series.push({
          name: 'SharePoint',
          data: this.stats.map(x => [x.timestamp, x.sharePoint / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.sqlVss > 0)) {
        series.push({
          name: 'SQL VSS',
          data: this.stats.map(x => [x.timestamp, x.sqlVss / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.sqlVdi > 0)) {
        series.push({
          name: 'SQL VDI',
          data: this.stats.map(x => [x.timestamp, x.sqlVdi / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.lotus > 0)) {
        series.push({
          name: 'Lotus',
          data: this.stats.map(x => [x.timestamp, x.lotus / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.meditech > 0)) {
        series.push({
          name: 'Meditech',
          data: this.stats.map(x => [x.timestamp, x.meditech / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.sybase > 0)) {
        series.push({
          name: 'Sybase',
          data: this.stats.map(x => [x.timestamp, x.sybase / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.iq > 0)) {
        series.push({
          name: 'IQ',
          data: this.stats.map(x => [x.timestamp, x.iq / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.informix > 0)) {
        series.push({
          name: 'Informix',
          data: this.stats.map(x => [x.timestamp, x.informix / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.sapHana > 0)) {
        series.push({
          name: 'SAP HANA',
          data: this.stats.map(x => [x.timestamp, x.sapHana / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.exchangeNmm9x > 0)) {
        series.push({
          name: 'Exchange Nmm9.X',
          data: this.stats.map(x => [x.timestamp, x.exchangeNmm9x / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.oracle > 0)) {
        series.push({
          name: 'Oracle',
          data: this.stats.map(x => [x.timestamp, x.oracle / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.db2 > 0)) {
        series.push({
          name: 'DB2',
          data: this.stats.map(x => [x.timestamp, x.db2 / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.otherApplications > 0)) {
        series.push({
          name: 'Other Applications',
          data: this.stats.map(x => [x.timestamp, x.otherApplications / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.exchangeNmm8x > 0)) {
        series.push({
          name: 'Exchange Nmm8.X',
          data: this.stats.map(x => [x.timestamp, x.exchangeNmm8x / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.mySql > 0)) {
        series.push({
          name: 'MySql',
          data: this.stats.map(x => [x.timestamp, x.mySql / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.hyperV > 0)) {
        series.push({
          name: 'HyperV',
          data: this.stats.map(x => [x.timestamp, x.hyperV / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.sapOracle > 0)) {
        series.push({
          name: 'SAP Oracle',
          data: this.stats.map(x => [x.timestamp, x.sapOracle / 1024 / 1024 / 1024 / 1024]),
        })
      }

      return series
    },
    seriesPerFilesystem() {
      const series = []
      if (this.stats.some(x => x.unixFilesystems > 0)) {
        series.push({
          name: 'Unix',
          data: this.stats.map(x => [x.timestamp, x.unixFilesystems / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.windowsFilesystems > 0)) {
        series.push({
          name: 'Windows',
          data: this.stats.map(x => [x.timestamp, x.windowsFilesystems / 1024 / 1024 / 1024 / 1024]),
        })
      }
      if (this.stats.some(x => x.vMwareFilesystems > 0)) {
        series.push({
          name: 'VMWare',
          data: this.stats.map(x => [x.timestamp, x.vMwareFilesystems / 1024 / 1024 / 1024 / 1024]),
        })
      }

      return series
    },
  },
  beforeMount() {
    this.$watch('timeRange', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      NetWorkerService.getAllStatisticListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
      }, { disableTenantFilter: true })
        .then(result => {
          this.stats = result
          this.statisticDataLoaded = true
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
